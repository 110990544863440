<template>
  <div class="potential">
    <rxNavBar title="查看详情"></rxNavBar>
    <van-tabs v-model="active"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.33rem" @scroll="scroll"
    >
      <van-tab title="详情">
        <van-row style="padding:10px 10px 0 0">
          <div class="subtitle"><span>合同详情</span></div>
        </van-row>
        <div class="panel">
          <div class="info first"><p>地址：<span>{{ items.address }}</span></p></div>
          <div class="info sec "><p>合同编号：<span>{{ items.contractNo }}</span></p></div>
          <div>
            <div class="panelInfo owner"><label>业主：<span>{{ items.owner }}</span></label></div>
           
          </div>
           <div class="panelInfo area"><label>面积：<span>{{ items.area }}㎡</span></label></div>
          <div class="info last"><p>收房年限：<span>{{ items.periodOfHouseCollection }}年</span></p></div>
        </div>
        <van-row style="padding:10px 10px 0 0">
          <div class="subtitle"><span>申请详情</span></div>
        </van-row>
        <div class="panel">
          <div class="panelHeader">
            <div class="panelInfo one"><label>申请人：<span>{{ items.applicant }}</span></label></div>
            <div class="panelInfo"><label>联系方式：<span>{{ items.contactInformation }}</span></label>
            </div>
          </div>
          <div>
            <div class="panelInfo one"><label>设计师：<span>{{ items.designer }}</span></label></div>
            <div class="panelInfo"><label>类型：<span>{{ items.type }}</span></label>
            </div>
          </div>
          <div>
            <div class="panelInfo one"><label>装修预算：<span
                style="color: rgb(244, 99, 76);">{{
                items.decorationBudget ? items.decorationBudget +'元': '暂无'
              }}</span></label></div>
            <div class="panelInfo"><label>交房日期：<span>{{ items.handoverDate }}</span></label>
            </div>
          </div>
          <div class="info float"><p>装修需求：<span>{{ items.decorationDemand }}</span></p></div>
          <div class="info last"><p>装修描述：<span>{{ items.decorationDescription }}</span></p></div>
        </div>

        <van-row v-if="0<imagesList.length" style="padding:10px 10px 0 0">
          <div class="subtitle"><span>设计图片</span></div>
        </van-row>
        <div v-if="0<imagesList.length" class="panel">
          <div class="photo_show" ref="wrapperBox">
            <div class="content">
              <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
                <van-image
                    width="100"
                    height="75"
                    fit="contain"
                    radius="10"
                    :src="url"
                />
                <div class="num">
                  {{ imagesList.length }}图
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="space" v-if="($route.query.afterRentStatusId=='2' || $route.query.afterRentStatusId=='3') && $route.query.fitmentProgramLimit">
              <van-button @click="decoratePlan()" round size="small" class="moreBtn two">修改</van-button>
        </div>

        <!--装配明细TAB-->
        <div v-if="1<afterRentStatus && (hands.chargeName || softs.chargeName )">
          <van-row style="padding:10px 10px 0 0">
            <div class="subtitle"><span>装配明细</span></div>
          </van-row>
          <div class="panel">
            <van-tabs v-model="active1"
                      line-width="20"
                      line-height="5"
                      title-active-color="rgb(255,81,45)"
                      sticky
                      swipeable
                      offset-top="1.2195121951219512rem"
            >
              <!--硬装方案TAB-->
              <van-tab title="硬装方案">
                <div class="panel">
                  <div class="info first"><p>硬装负责人：<span>{{ hands.chargeName }}</span></p></div>
                  <div class="info sec"><p>硬装首期占比：<span
                      style="color: #9d9d9d !important;">{{ hands.firstPhaseProportion }}</span></p>
                  </div>
                  <div class="info other"><p>
                    预计工期：<span>{{ hands.appointmentBeginTime }}至{{ hands.appointmentEndTime }}</span></p></div>
                  <div class="info other"><p>预计时长：<span>{{ hands.appointmentTimeLong }}天</span></p></div>
                  <div class="info last bordBottom"><p>预计硬装费用：<span>{{ hands.budgetFee }}元</span></p></div>
                  <div style="margin-top: 6px" class="bordBottom" v-if="0<hardwareList.length">
                    <div class="t_table">
                      <van-row style="background-color: #f3f9ff">
                        <van-col span="7" class="t_title">项目名称</van-col>
                        <van-col span="8" class="t_title">数量</van-col>
                        <van-col span="4" class="t_title">实际费用</van-col>
                        <van-col span="4" class="t_title">(预计费用)</van-col>
                        <van-col span="1" class="t_title"></van-col>
                      </van-row>
                      <div class="t_body" v-for="items in hardwareList" :key="items.goodsClassId">
                        <span class="diamod"></span>
                        <span class="t_title"
                              style="color: #3B98FF;font-size:0.35rem; font-weight:400;">{{
                            items.goodsAllocationClassName
                          }}</span>
                        <van-row v-for="item in items.childrenList" :key="item.id">
                          <van-col span="8" class="t_td" style="text-align: unset;padding-left: 30px;">
                            {{ item.itemName }}
                          </van-col>
                          <van-col span="6" class="t_td">{{ item.totalCount }}</van-col>
                          <van-col span="10" class="t_td">{{Number(item.realMoney?item.realMoney:0).toFixed(2) || Number(item.estimateMoney).toFixed(2)}}元<span
                              style="color:rgb(136,136,136) ">（{{ item.estimateMoney }}）</span></van-col>
                        </van-row>
                      </div>
                    </div>
                  </div>
                  <div class="info first" v-if="hands.beginTime && hands.endTime"><p>实际工期：{{ hands.beginTime }} 至
                    {{ hands.endTime }}</p></div>
                  <div class="info last" v-if="hands.finalFee"><p>实际硬装费用：<span
                      style="font-weight: 900;color: #fe6641">{{ hands.finalFee }}</span></p></div>
                </div>
                <div class="potential" v-if="hands.finalFee || softs.finalFee">
                  <van-row style="padding:30px 10px 0 0">
                    <div><span style="font-size: 12px;color: rgb(244, 99, 76);line-height: 22px;font-weight: 900;">实际费用</span></div>
                  </van-row>
                  <div class="panel">
                    <div class="info first">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;">硬装：</span></div>
                      <div style="text-align: right"><span>{{ hands.finalFee }}元</span></div>
                    </div>
                    <div class="info footer bordBottom">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;">软装：</span></div>
                      <div style="text-align: right"><span>{{ softs.finalFee }}元</span></div>
                    </div>
                    <div class="info footer">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;color: #fe6641">总实际费用：</span></div>
                      <div style="text-align: right"><span
                          style="color: #fe6641;font-weight: 900">{{
                          hands.finalFee && softs.finalFee ? Number(hands.finalFee) + Number(softs.finalFee) +'元': ''
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </van-tab>
              <!--软装方案TAB-->
              <van-tab title="软装方案">
                <div class="panel">
                  <div class="info first"><p>软装负责人：<span>{{ softs.chargeName }}</span></p></div>
                  <div class="info sec"><p>软装首期占比：<span
                      style="color: #9d9d9d !important;">{{ softs.firstPhaseProportion }}</span></p>
                  </div>
                  <div class="info other"><p>
                    预计工期：<span>{{ softs.appointmentBeginTime }}至{{ softs.appointmentEndTime }}</span></p></div>
                  <div class="info other"><p>预计时长：<span>{{ softs.appointmentTimeLong }}天</span></p></div>
                  <div class="info last bordBottom"><p>预计软装费用：<span>{{ softs.budgetFee }}元</span></p></div>
                  <div style="margin-top: 6px" class="bordBottom" v-if="0<softwareList.length">
                    <div class="t_table">
                      <van-row style="background-color: #f3f9ff">
                        <van-col span="7" class="t_title">项目名称</van-col>
                        <van-col span="8" class="t_title">数量</van-col>
                        <van-col span="4" class="t_title">实际费用</van-col>
                        <van-col span="4" class="t_title">(预计费用)</van-col>
                        <van-col span="1" class="t_title"></van-col>
                      </van-row>
                      <div class="t_body" v-for="items in softwareList" :key="items.goodsClassId">
                        <span class="diamod"></span>
                        <span class="t_title"
                              style="color: #3B98FF;font-size:0.35rem; font-weight:400;">{{ items.roomName }}</span>
                        <van-row v-for="item in items.childrenList" :key="item.id">
                          <van-col span="8" class="t_td" style="text-align: unset;padding-left: 30px;">
                            {{ item.itemName }}
                          </van-col>
                          <van-col span="6" class="t_td">{{ item.totalCount }}</van-col>
                          <van-col span="10" class="t_td">{{ Number(item.realMoney?item.realMoney:0).toFixed(2) || Number(item.estimateMoney).toFixed(2) }}元<span
                              style="color:rgb(136,136,136) ">（{{ item.estimateMoney }}）</span></van-col>
                        </van-row>
                      </div>
                    </div>
                  </div>
                  <div class="info first" v-if="softs.beginTime && softs.endTime"><p>实际工期：{{ softs.beginTime }} 至
                    {{ softs.endTime }}</p></div>
                  <div class="info last" v-if="softs.finalFee"><p>实际软装费用：<span
                      style="font-weight: 900;color: #fe6641">{{ softs.finalFee }}元</span></p></div>
                </div>
                <div class="potential" v-if="hands.finalFee || softs.finalFee">
                  <van-row style="padding:30px 10px 0 0">
                    <div><span style="font-size: 12px;color: rgb(244, 99, 76);line-height: 22px;font-weight: 900;">实际费用</span></div>
                  </van-row>
                  <div class="panel">
                    <div class="info first">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;">硬装：</span></div>
                      <div style="text-align: right"><span>{{ hands.finalFee }}元</span></div>
                    </div>
                    <div class="info footer bordBottom">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;">软装：</span></div>
                      <div style="text-align: right"><span>{{ softs.finalFee }}元</span></div>
                    </div>
                    <div class="info footer">
                      <div style="width: 50%;float: left"><span
                          style="font-weight: 900;color: #fe6641">总实际费用：</span></div>
                      <div style="text-align: right"><span
                          style="color: #fe6641;font-weight: 900">{{
                          hands.finalFee && softs.finalFee ? Number(hands.finalFee) + Number(softs.finalFee) +'元': ''
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </van-tab>
            </van-tabs>
          </div>
          <van-row v-if="0<imagesList1.length" style="padding:10px 10px 0 0">
            <div class="subtitle"><span>施工图片</span></div>
          </van-row>
          <div v-if="0<imagesList1.length" class="panel">
            <div class="photo_show" ref="wrapperBox1">
              <div class="content">
                <div class="imgWrap" v-for="(url,i) in imagesList1" @click="previewImg1(i)">
                  <van-image
                      width="100"
                      height="75"
                      fit="contain"
                      radius="10"
                      :src="url"
                  />
                  <div class="num">
                    {{ imagesList1.length }}图
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div v-for="item in approvalRecords">
          <van-row style="padding:10px 10px 0 0">
            <div class="subtitle"><span>{{ '审批' == item.changeStatus ? '装修' : item.changeStatus }}审批</span></div>
          </van-row>
          <div class="panel">
            <div class="info first"><p>审批人：<span>{{ item.changeStaffName }}</span></p></div>
            <div class="info float"><p>审批时间：<span>{{ item.addTime }}</span></p></div>
            <div class="info float"><p>审批结果：<span :style="{'color':( '通过'==item.dealResult ? '#38bfa4' : '#e74f6c') }">{{
                item.dealResult
              }}</span>
            </p></div>
            <div v-if="'驳回'==item.dealResult" class="info float"><p>驳回原因：<span>{{ item.refuseReason }}</span></p></div>
            <div class="info float"><p>审批意见：<span>{{ item.remarks }}</span></p></div>
            <div v-if="0!=item.evaluateStar.toString().length" class="info last">
              <p>服务人员评价：
                <van-rate
                    void-icon="star"
                    void-color="#ff5d3b"
                    :count="item.evaluateStar"
                    readonly
                    style="position: absolute"
                />
              </p>
            </div>
          </div>
        </div>
        <van-row v-if="0<imagesList2.length" style="padding:10px 10px 0 0">
          <div class="subtitle"><span>验收图片</span></div>
        </van-row>
        <div v-if="0<imagesList2.length" class="panel">
          <div class="photo_show" ref="wrapperBox2">
            <div class="content">
              <div class="imgWrap" v-for="(url,i) in imagesList2" @click="previewImg2(i)">
                <van-image
                    width="100"
                    height="75"
                    fit="contain"
                    radius="10"
                    :src="url"
                />
                <div class="num">
                  {{ imagesList2.length }}图
                </div>
              </div>
            </div>

          </div>
        </div>

      </van-tab>

      <!--账单TAB-->
      <van-tab title="账单">
        <van-row style="padding:10px 10px 0 0"></van-row>
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="noContent ? '' : '已经到底了'"
              @load="onLoad"
          >
            <div class="panelBill">
              <div v-for="body in billList">
                <div style="width: 50%;float: left;padding: 16px 0px 3px 14px"><span
                    style="font-weight: 900;font-size: 15px">{{ body.billFeeType }}</span></div>
                <div style="text-align: right;padding: 16px 14px 3px 0px;position: relative;overflow: hidden">
              <span
                  style="font-weight: 900;font-size: 15px;color: #fe6641">{{ body.totalAmount }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" v-show="body.billDescription">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.billDescription }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" class="bordBottom">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.addTime }}</span>
                </div>
              </div>
            </div>
          </van-list>
          <common-empty v-if="noContent"></common-empty>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>


  </div>
</template>
<script>
import {
  NavBar,
  Tab,
  Tabs,
  Search,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Overlay,
  Popup,
  Rate,
  List,
  PullRefresh,
  Field, Image as VanImage, ImagePreview, Collapse, CollapseItem
} from 'vant'
import BScroll from "better-scroll";
import Divider from "vant/lib/divider";
import {
  afterrentHisotry, browseBillList, decorationConstructionDetails,
  queryRenovation, getStaffFuntionModelList
} from "../../getData/getData";
import {getStaffId, getUserId, globaluserId, responseUtil, checkAuthList} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar"

export default {
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      noContent: false,
      page: 1,
      afterRentStatus: '',
      offsetTop: '49.9',
      approvalRecords: [],
      items: {
        address: '',
        contractNo: '',
        owner: '',
        area: '',
        periodOfHouseCollection: '',
        applicant: '',
        contactInformation: '',
        designer: '',
        type: '',
        decorationBudget: '',
        handoverDate: '',
        decorationDemand: '',
        decorationDescription: '',

      },
      hands: {
        chargeName: '',
        firstPhaseProportion: '',
        appointmentBeginTime: '',
        appointmentEndTime: '',
        appointmentTimeLong: '',
        budgetFee: '',
        beginTime: '',
        endTime: '',
        finalFee: ''
      },
      softs: {
        chargeName: '',
        firstPhaseProportion: '',
        appointmentBeginTime: '',
        appointmentEndTime: '',
        appointmentTimeLong: '',
        budgetFee: '',
        beginTime: '',
        endTime: '',
        finalFee: ''
      },
      //硬装施工方案
      hardwareList: [],
      //软装施工方案
      softwareList: [],

      billList: [],
      imagesList: [],
      imagesList1: [],
      imagesList2: [],
      active: 0,
      active1: 0,
      authButtonsList: [],
    }
  },
  name: 'fitmentAudit',
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]: BScroll,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Divider.name]: Divider,
    [Rate.name]: Rate,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    rxNavBar
  },
  methods: {

    onRefresh() {
      this.billList = [];
      setTimeout(()=>{
        this.page = 1;
        this.finished = false
        this.loading = true
        this.noContent = false
        this.isLoading = false
        this.onLoad();
      },500)

    },

    onLoad() {
      let that = this
      let initdata = {}
      //员工id
      initdata.user_id = getStaffId()
      //initdata.staff_id = getStaffId()
      initdata.currentPage = that.page
      initdata.numberPage = 10
      // //租后id 可不填
      initdata.afterRent_id = that.$route.query.arid
      //套房源id 可不填
      if (that.$route.query.setHouse_id)
        initdata.setHouse_id = that.$route.query.setHouse_id
      browseBillList(initdata).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          let list = response.data.data.data
          // let count = response.data.data.pageCount
          if (list)
            that.billList = that.billList.concat(response.data.data.data)
          that.loading = false
          if (that.billList.length >= response.data.data.pageCount)
            that.finished = true
          if (1 == that.page && (!list || 0 == list.length))
            that.noContent = true
          else
            that.noContent = false
          that.page++;
        })
      })
    },

    getStaffFuntionModelList() {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'afterRentDecoration_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },

    // 滚动时获取nav高度并赋值
    scroll() {
      if (this.$refs.navBar) {
        this.offsetTop = this.$refs.navBar.clientHeight
      }

    },

    initData() {
      let that = this
      let initData = {}
      initData.user_id = globaluserId()
      initData.rtid = that.$route.query.rtid
      //initData.rtid = 34
      queryRenovation(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          let data = response.data.data
          let datas = response.data.data.data
          //判断是否显示装配明细
          that.afterRentStatus = Number(datas.afterRentStatus)
          //地址
          that.items.address = data.contractInfo.dispatchAddress
          //合同编号
          that.items.contractNo = data.contractInfo.contractCode
          //业主
          that.items.owner = data.contractInfo.ownerName
          //面积
          that.items.area = data.contractInfo.builtUpArea
          //收房年限
          that.items.periodOfHouseCollection = data.contractInfo.contractYears
          //申请人
          that.items.applicant = datas.applicant
          //联系方式
          that.items.contactInformation = datas.applicantMobile
          //设计师
          that.items.designer = datas.designGlobaluserName
          //类型
          that.items.type = datas.renovationTypeStr
          //装修预算
          if(datas.budgetFee)
          that.items.decorationBudget = datas.budgetFee
          if(datas.budget)
          that.items.decorationBudget = datas.budget
          //交房日期
          that.items.handoverDate = datas.handoverDate
          //装修需求
          that.items.decorationDemand = datas.demand
          //装修描述
          that.items.decorationDescription = datas.afterRentDesprition

          that.hands.chargeName = datas.hardChargeName
          that.hands.firstPhaseProportion = datas.hardFirstPhaseProportion
          that.hands.appointmentBeginTime = datas.hardAppointmentBeginTime
          that.hands.appointmentEndTime = datas.hardAppointmentEndTime
          that.hands.appointmentTimeLong = datas.hardAppointmentTimeLong
          that.hands.budgetFee = datas.handBudgetFee
          that.hands.beginTime = datas.hardBeginTime
          that.hands.endTime = datas.hardEndTime
          that.hands.finalFee = datas.hardFinalFee || datas.hardRealFee

          that.softs.chargeName = datas.softChargeName
          that.softs.firstPhaseProportion = datas.softFirstPhaseProportion
          that.softs.appointmentBeginTime = datas.softAppointmentBeginTime
          that.softs.appointmentEndTime = datas.softAppointmentEndTime
          that.softs.appointmentTimeLong = datas.softAppointmentTimeLong
          that.softs.budgetFee = datas.softBudgetFee
          that.softs.beginTime = datas.softBeginTime
          that.softs.endTime = datas.softEndTime
          that.softs.finalFee = datas.softFinalFee || datas.softRealFee
          if (data.imgDesignList)
            for (let i = 0; i < data.imgDesignList.length; i++)
              that.imagesList.push(data.imgDesignList[i].path)
          if (data.imgDoneList)
            for (let i = 0; i < data.imgDoneList.length; i++)
              that.imagesList1.push(data.imgDoneList[i].path)
          if (data.imgFinishList)
            for (let i = 0; i < data.imgFinishList.length; i++)
              that.imagesList2.push(data.imgFinishList[i].path)
          that.$nextTick(() => {
            for (let i = 0; i < Object.values(that.$refs).length; i++)
              if (!that[scroll + i]) {
                that[scroll + i] = new BScroll(Object.values(that.$refs)[i], {
                  scrollX: true,
                  eventPassthrough: 'vertical'
                })
              }
          })
        })
      })
    },

    initAfterrentHisotry() {
      let that = this
      let initdata = {}
      initdata.rtid = that.$route.query.rtid
      //initdata.rtid = 34
      afterrentHisotry(initdata).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data.data)
          that.approvalRecords = response.data.data.data
        })
      })
    },

    // initBrowseBillList() {
    //   let that = this
    //   let initdata = {}
    //   //员工id
    //   initdata.user_id = getStaffId()
    //   //initdata.staff_id = getStaffId()
    //   initdata.currentPage = 1
    //   initdata.numberPage = 10
    //   // //租后id 可不填
    //   initdata.afterRent_id = that.$route.query.arid
    //   //套房源id 可不填
    //   if (that.$route.query.setHouse_id)
    //     initdata.setHouse_id = that.$route.query.setHouse_id
    //   browseBillList(initdata).then(function (response) {
    //     responseUtil.dealResponse(that, response, () => {
    //       //console.log(response.data.data.data)
    //       let list = response.data.data.data
    //       if (list) {
    //         that.billList = response.data.data.data
    //       }
    //     })
    //   })
    // },

    previewImg(start) {
      ImagePreview(
          {
            images: this.imagesList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    previewImg1(start) {
      ImagePreview(
          {
            images: this.imagesList1,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    previewImg2(start) {
      ImagePreview(
          {
            images: this.imagesList2,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    //施工方案初始化
    decorationConstructionDetails() {
      var that = this
      let initData = {}
      initData.id = that.$route.query.arid
      decorationConstructionDetails(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data);
          that.hardwareList = response.data.data.selectedHardList
          that.softwareList = response.data.data.selectedSoftList

        })
      })
    },
    decoratePlan() {
      this.$router.push({
        name: 'decoratePlan',
        query: {
          //套房源id
          setHouse_id: this.$route.query.setHouse_id,
          //装修id
          rtid: this.$route.query.rtid,
          arid: this.$route.query.arid,
          location:'detail'
        }
      })
    },    
  },
  mounted() {
    this.getStaffFuntionModelList()
  },
  created() {

    this.initData()
    //装修详情施工方案详情
    this.decorationConstructionDetails()
    this.initAfterrentHisotry()
    //this.initBrowseBillList()
    // this.$nextTick(() => {
    //     this.scroll = new BScroll(this.$refs.wrapperBox, {
    //         scrollX: true,
    //         eventPassthrough: 'vertical'
    //     })
    //     this.scroll = new BScroll(this.$refs.wrapperBox1, {
    //         scrollX: true,
    //         eventPassthrough: 'vertical'
    //     })
    //     this.scroll = new BScroll(this.$refs.wrapperBox2, {
    //         scrollX: true,
    //         eventPassthrough: 'vertical'
    //     })
    // })
  },
}
</script>
<style lang="less" scoped>
@radius: 16px;
@border: 1px solid rgb(244, 244, 244);

.potential {
  width: 100%;
  background-color: rgb(250, 250, 250);
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;

  .subtitle {
    padding: 5px 0px 0px 15px;

    span {
      font-size: 12px;
      color: rgb(244, 99, 76);
      line-height: 22px;
      font-weight: 900;
    }
  }

  .bordBottom {
    border-bottom: @border;
  }


  .panel {
    width: 345px;
    margin: 0px auto 15px;
    background-color: rgb(255, 255, 255);
    border-radius: @radius;
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;

    .info {
      &.first {
        padding: 10px 8px 0px 14px;

        p {
          margin: 6px 0;
          font-weight: 900;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      &.sec {
        padding: 0px 14px 0px 14px;

        p {
          margin: 6px 0;
          font-weight: 900;

          span {
            font-weight: normal;
            color: rgb(244, 99, 76);
          }
        }
      }

      &.other {
        padding: 0px 14px 0px 14px;

        p {
          margin: 6px 0;
          font-weight: 900;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      &.float {
        padding: 0px 8px 0px 14px;

        p {
          margin: 0 0 6px 0;
          font-weight: 900;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      &.last {
        padding: 0px 8px 20px 14px;

        p {
          margin: 0 0 6px 0;
          font-weight: 900;

          span {
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }
      }

      &.footer {
        padding: 6px 8px 12px 14px;

        span {
          font-weight: normal;

        }

      }
    }

    .panelHeader {
      padding-top: 14px;
    }

    .panelFooter {
      padding-bottom: 14px;
    }

    .panelInfo {
      padding: 0 6px 6px 14px;
      font-size: 14px;

      &.one {
        width: 42.5%;
        float: left;
      }
      &.owner{
        width: 42.5%;
      }
      &.area{
        width: 42.5%;
        float: left;
      }
      label {
        font-weight: 900;

        .fitment {
          color: #f00;
        }

        span {
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }

    }

  }

  .panelBill {
    width: 100%;
    margin: 0px 0px;
    background-color: rgb(255, 255, 255);
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
  }


  // 图片墙
  .photo_show {
    width: 325px;
    position: relative;
    margin: 15px 10px;
    height: 75px;

    .content {
      position: absolute;
      display: flex;
      justify-content: space-around;
      left: 0px;

      .imgWrap {
        margin-right: 10px;
      }
    }

    .num {
      position: absolute;
      left: 4px;
      top: 6px;
      font-size: 10px;
      background-color: rgb(0, 0, 0);
      opacity: .1;
      color: rgb(255, 255, 255);
      padding: 2px 6px;
      display: inline-block;
      border-radius: 10px;
      font-weight: 900;
      line-height: 10px;
    }
  }

  // 循环表单
  .t_table {
    .t_header {
      text-align: center;
      background-color: #79c5ff;
      color: rgb(136, 136, 136);
      font-weight: normal;
      font-size: 10px;
      height: 20px;
      line-height: 40px;
    }

    .t_title {
      color: rgb(136, 136, 136);
      text-align: center;
      font-weight: 400;
      font-size: 10px;
    }

    .t_td {
      text-align: center;
      height: 40px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      line-height: 40px;
      color: black;
      font-size: 13px;
    }
  }

  .van-collapse-item__arrow {
    float: left;
    margin-left: 5px;
    margin-right: 15px;
  }

  .van-collapse {
    border: 0;
  }

  .deploy-setting .van-collapse-item__header {
    border-bottom: 1px solid #2C8DF4;
  }

  .deploy-setting .van-collapse-item__wrap {
    border-bottom: 0px;
  }

  .collapse-item-title {
    float: left;
    font-weight: 900;
    font-size: 14px;
    color: rgb(244, 99, 76);
  }

  .diamod {
    width: 8px;
    height: 8px;
    background-color: #3B98FF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0 7px 0 33px;
    display: inline-block;
  }

  .t_body {
    margin-top: 10px;
  }

  .space{
    padding-left:8px;
  }
      .moreBtn {
        width: 80px;
        background-clip: padding-box, border-box;
        background-origin: border-box;
        border-radius: 8px;

        &.one {
          color: rgba(255, 93, 59, 1);
          background-color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(white, white), /* 底色，即原有的背景 */ linear-gradient(to right, #f5c998, #fe5e3e); /* 模拟渐变边框 */
        }

        &.two {
          color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(to right, rgb(255, 190, 110), rgb(255, 100, 60)); /* 模拟渐变边框 */
        }

        &.three {
          color: rgb(255, 255, 255);
          font-size: 12px;
          border: 1px solid transparent;
          background-image: linear-gradient(to right, rgb(124, 198, 255), rgb(54, 147, 255)); /* 模拟渐变边框 */
        }
      }
}
</style>
